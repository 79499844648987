@use "./homad";

.jwplayer.vgtv2 {
  .jw-title {
    display: none !important;
  }

  .jw-preview {
    background-size: cover !important;
  }

  .vgp-skin:has(.vgp-pause-ad-layout) {
    pointer-events: none;
  }

  .svp-video-preview {
    display: block;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    margin: 0 auto;
    object-fit: cover;
  }

  &.svp-flag-video-preview {
    .jw-preview {
      background-image: none !important;
    }
  }

  &.jw-flag-ads-googleima {
    .vgp-skin:not(.vgp-no-touch) {
      display: none !important;
    }
  }

  //  styles copy-pasted from https://github.schibsted.io/svp/web-player/blob/master/src/sass/plugins/_pause.scss
  .svp-pause-view {
    position: absolute;
    z-index: 99;
    background: #000;
    inset: 0;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;

    .jw-icon-playback,
    .svp-pause-close {
      display: none;
    }

    &.svp-pause-view--visible {
      opacity: 1;
      pointer-events: all;
      visibility: visible;
    }

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 50px;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0%) 0%,
        rgba(0, 0, 0, 50%) 100%
      );
      content: "";
      pointer-events: none;
    }
  }

  .svp-player-error-wrapper {
    display: none;
  }
}
