.aab-spacer {
  flex-grow: 1;
}

.aab-layout {
  --font-size-l: 17px;
  --space-s: 8px;
  --space-l: 15px;
  --space-xl: 20px;
  --border-radius-l: 9px;
  --color-fill-secondary: rgba(60, 60, 67, 60%);
  --color-label-secondary: rgba(235, 235, 245, 60%);
  --color-base: #fff;
  --button-size: 50px;

  position: absolute;
  z-index: 10001;
  display: flex;
  flex-direction: column;
  padding: 16px;
  font-family: Inter, "Helvetica Neue", Helvetica, sans-serif;
  inset: 0;

  .jw-breakpoint-1 &,
  .jw-breakpoint-2 &,
  .jw-breakpoint-3 & {
    --button-size: 36px;
    --font-size-l: 11px;
    --space-xl: 12px;
    --border-radius-l: 7px;
  }
}

.aab-controls {
  display: flex;

  & > * + * {
    margin-left: var(--space-s);
  }

  & > button, & > a {
    display: inline-flex;
    overflow: visible;
    width: auto;
    min-width: var(--button-size);
    height: var(--button-size);
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    border-radius: var(--border-radius-l);
    -webkit-appearance: none;
    background-color: var(--color-fill-secondary);
    color: var(--color-base);
    cursor: pointer;
    font: inherit;
    font-size: var(--font-size-l);
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    line-height: var(--space-xl);
    pointer-events: all;
    text-decoration: none;

    & svg {
      width: 20px;
      height: 20px;
    }
  }

  & > a {
    padding: 0 var(--space-xl);
    font-size: 11px;
    line-height: 13px;
    text-decoration: none;

    .aab-labels {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-left: var(--space-s);
      font-weight: 600;

      & > span {
        color: var(--color-label-secondary);
      }
    }
  }
}
